
import React, { useRef, useEffect } from "react";
import anime from "animejs";

const keyframes = [
  {
    "id": "keyframe-1717512947741",
    "viewBox": "0 0 413 361",
    "paths": [
      {
        "d": "M272.441 192.552C265.58 196.735 258.902 199.428 251.886 204.906C231.578 220.736 230.732 253.861 223.976 276.724C228.181 280.358 247.694 286.07 263.68 281.73",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M321.089 163.218C320.686 168.395 322.417 173.14 325.229 177.454",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M334.445 187.873C343.154 195.18 352.227 202.095 361.327 208.893C364.724 211.442 368.239 214.03 370.491 217.638C372.639 221.089 373.459 225.207 373.915 229.259C377.143 258.436 365.818 287.273 353.074 311.391",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M262.599 198.043C264.981 197.102 267.116 196.945 269.199 197.834C270.696 198.474 272.258 199.599 273.235 200.932C275.929 204.631 276.984 209.181 277.908 213.599C278.741 217.586 279.431 221.599 280.004 225.638C281.11 233.508 281.774 241.429 282.477 249.338C285.744 285.888 288.999 322.451 292.266 359",
        "stroke": "#294C72",
        "strokeWidth": 2.98783,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M265.437 109.975C260.672 125.191 260.802 143.139 270.67 155.65C272.713 158.238 275.278 160.63 278.467 161.467C281.656 162.303 285.679 160.552 288.621 157.362",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M269.719 105.636C271.945 106.08 274.393 105.74 276.41 106.734C281.201 109.087 279.404 115.793 276.944 120.224C278.819 118.211 280.784 116.146 283.388 115.035C285.991 113.924 289.428 114.041 291.394 116.028C292.318 116.956 297.486 124.734 288.204 132.447C289.571 131.309 292.292 137.597 292.63 138.316C296.588 146.643 302.094 160.068 321.087 156.186C320.202 144.238 332.399 134.172 338.244 123.61C346.862 108.054 348.892 87.9623 332.23 70.9816C329.848 68.5502 321.087 64.4717 318.366 63.8834C301.756 60.3147 288.374 67.2691 276.567 77.1124C270.318 82.3151 250.583 101.78 269.719 105.61V105.636Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M334.095 69.8091C336.64 70.1979 339.242 70.2815 341.789 69.9364C347.478 69.1662 352.765 66.1697 356.108 61.8261C362.612 53.361 360.727 40.715 353.164 32.9921C341.573 21.143 317.551 25.1234 314.084 41.7267C311.421 54.4619 320.357 67.7379 334.082 69.81L334.095 69.8091Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M237.333 285.587C237.086 294.149 234.482 302.62 229.887 309.836",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M25.7449 226.54C23.6882 242.776 22.6207 252.384 19.7568 270.057",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M130.106 358.895C126.044 351.392 125.042 348.333 123.688 345.169C102.977 296.842 98.9543 242.043 102.886 190.121L89.3604 186.984",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M42.602 211.546C19.0791 235.364 2.35148 234.344 2 235.037L20.5241 198.788",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M72.3726 69.2429C86.3015 62.8245 100.894 74.4195 103.094 82.5634C115.474 128.342 96.1428 162.722 70.0425 188.356",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M49.4634 213.219C49.4634 213.219 50.4397 224.291 54.1237 227.416C67.3496 224.095 71.7626 217.167 71.7626 217.167C71.7626 217.167 75.1081 224.997 80.914 225.416L76.5531 211.585C75.7981 209.206 74.6525 206.984 73.1294 205.01L70.1354 201.101",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M108.198 60.7981C112.415 67.0989 121.853 69.478 124.951 68.6937C130.172 67.3603 132.28 61.138 133.166 55.8046C134.728 46.3665 132.306 37.6474 127.243 29.5034",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M110.203 26.0394C104.488 33.3598 97.3155 39.6345 89.2836 44.262C87.396 45.3601 85.0268 46.3797 83.1262 45.2947C81.9807 44.6411 81.3298 43.3731 80.9913 42.092C79.9629 38.1442 79.5985 28.1702 94.4255 12.5229C98.0314 8.71886 103.941 5.28089 109.214 4.61421C117.245 3.59458 120.995 7.01947 122.4 14.6928C118.547 15.8824 113.522 21.7779 110.19 26.0525L110.203 26.0394Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M95.402 11.3466C96.157 8.77136 96.4825 4.43142 95.376 2",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M57.7023 82.9949C57.7023 82.9949 10.1489 134.277 15.8506 153.075C15.8506 153.075 31.654 200.513 46.8586 213.847L74.5211 198.618C69.0146 187.82 61.7768 176.892 57.494 165.598C50.5816 147.362 62.4928 138.669 75.8619 128.251",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M229.927 250.122L230.213 223.416H155.453V298.241",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M179.092 223.416H222.519V179.807H179.092V223.416Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M182.815 173.728C178.324 166.473 182.919 157.231 186.941 149.715C190.274 143.493 193.268 136.97 194.427 130.002C196.509 117.466 192.461 104.747 187.605 93.0081C182.75 81.2693 176.957 69.6743 175.291 57.0728C174.744 52.9028 175.876 47.1902 180.068 46.9549C185.366 46.6673 187.163 51.6086 192.266 47.334C195.924 44.2621 196.561 41.0071 195.885 36.458C194.882 29.7128 190.586 24.7192 189.076 18.1832C187.801 12.6537 191.524 7.37251 197.199 6.88884C204.294 6.28752 208.668 14.1177 209.358 20.2485C210.048 26.314 207.965 32.5625 209.332 38.5103C210.881 45.2294 216.439 50.1184 220.267 55.844C225.161 63.1513 227.296 72.2757 226.164 81.0078C224.745 91.9623 218.522 101.623 214.981 112.067C211.441 122.525 211.363 135.793 219.902 142.761C221.672 144.199 223.768 145.336 225.005 147.257C226.932 150.251 226.177 154.251 224.784 157.532C219.915 168.931 193.086 190.369 182.815 173.768V173.728Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M167.286 232.252V287.94",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M80.0012 252.789L69.6001 317.535",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M104.789 76.6941C104.789 76.6941 111.024 84.0014 118.314 81.9099L107.913 179.036",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M122.907 20.2485C123.831 25.2421 124.026 31.7782 119.418 33.9089C117.179 34.9416 114.393 34.4841 112.336 35.8567C110.227 37.2554 109.616 40.0659 108.067 42.079C106.517 44.0921 102.521 44.5758 102.065 42.079",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M85.5469 51.0205C85.5469 51.0205 92.4983 54.014 98.4473 66.9685",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M352.606 151.724L388.862 150.796",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M335.396 169.15L408.896 167.269",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M273.25 187.714L394.261 184.617",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M290.278 175.405L290.97 202.456",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M312.438 179.754L313.01 202.14",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M334.257 170.958L334.691 187.92",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M355.77 150.1L356.846 192.165",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M377.726 147.106L378.859 191.353",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M400.081 159.178L400.594 179.224",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M335.957 186.044C339.604 189.638 387.002 210.406 410.776 164.998C399.639 160.798 392.4 150.13 379.851 146.737C371.721 144.539 348.892 142.966 329.976 177.723C317.338 180.518 290.049 181.714 283.302 168.444C279.845 173.789 270.195 186.472 272.802 193.191C274.265 196.933 281.802 199.395 285.067 200.75C293.819 204.37 323.738 208.769 335.971 186.056L335.957 186.044Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M127.45 100.25C129.272 130.839 143.136 160.552 165.383 181.533C161.217 185.154 156.505 188.513 151.064 189.298C145.622 190.082 139.348 187.507 137.525 182.291C138.163 185.899 138.775 189.128 139.608 192.553C140.441 195.978 139.191 200.697 135.69 200.958C132.709 201.18 130.704 197.991 129.584 195.206C124.377 182.2 122.841 167.742 125.198 153.925",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      }
    ]
  },
  {
    "id": "keyframe-1717512952183",
    "viewBox": "0 0 415 366",
    "paths": [
      {
        "d": "M272.465 197.68C265.604 201.863 258.926 204.556 251.91 210.033C231.602 225.863 230.756 258.988 224 281.851C228.205 285.485 247.718 291.198 263.704 286.858",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M323.058 167C322.655 172.177 324.386 176.922 327.198 181.236",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M334.469 193C343.178 200.307 352.251 207.223 361.351 214.02C364.748 216.569 368.263 219.157 370.515 222.765C372.663 226.216 373.483 230.334 373.939 234.386C377.167 263.563 365.842 292.401 353.097 316.519",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M262.623 203.17C265.005 202.229 267.14 202.072 269.223 202.961C270.72 203.602 272.282 204.726 273.258 206.059C275.953 209.759 277.008 214.308 277.932 218.726C278.765 222.713 279.455 226.726 280.028 230.766C281.134 238.635 281.798 246.557 282.501 254.466C285.768 291.015 289.023 327.578 292.29 364.128",
        "stroke": "#294C72",
        "strokeWidth": 2.98783,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M265.059 112C260.294 127.216 260.424 145.164 270.292 157.674C272.335 160.262 274.9 162.655 278.089 163.491C281.279 164.328 285.301 162.576 288.243 159.386",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M270.15 107.701C272.376 108.146 274.823 107.806 276.841 108.799C281.631 111.152 279.835 117.858 277.375 122.29C279.249 120.277 281.215 118.211 283.818 117.1C286.422 115.989 289.859 116.107 291.824 118.094C292.749 119.022 297.917 126.8 288.635 134.512C290.002 133.375 292.722 139.663 293.061 140.382C297.018 148.709 302.525 162.134 321.517 158.251C320.632 146.303 332.83 136.238 338.675 125.675C347.292 110.12 349.323 90.0277 332.661 73.047C330.278 70.6156 321.517 66.5371 318.797 65.9488C302.186 62.3801 288.804 69.3345 276.997 79.1778C270.749 84.3805 251.014 103.845 270.15 107.675V107.701Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M333.807 71.2542C336.351 71.643 338.954 71.7265 341.501 71.3815C347.189 70.6112 352.477 67.6148 355.819 63.2712C362.324 54.806 360.438 42.1601 352.876 34.4372C341.285 22.5881 317.262 26.5684 313.795 43.1718C311.133 55.9069 320.068 69.183 333.794 71.255L333.807 71.2542Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M237.357 290.714C237.11 299.277 234.506 307.747 229.911 314.963",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M25.7449 226.54C23.6882 242.776 22.6207 252.384 19.7568 270.057",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M130.106 358.895C126.044 351.392 125.042 348.333 123.688 345.169C102.977 296.842 98.9543 242.043 102.886 190.121L89.3604 186.984",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M42.602 211.546C19.0791 235.364 2.35148 234.344 2 235.037L20.5241 198.788",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M72.3726 69.2429C86.3015 62.8245 100.894 74.4195 103.094 82.5634C115.474 128.342 96.1428 162.722 70.0425 188.356",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M49.4634 213.219C49.4634 213.219 50.4397 224.291 54.1237 227.416C67.3496 224.095 71.7626 217.167 71.7626 217.167C71.7626 217.167 75.1081 224.997 80.914 225.416L76.5531 211.585C75.7981 209.206 74.6525 206.984 73.1294 205.01L70.1354 201.101",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M108.198 60.7981C112.415 67.0989 121.853 69.478 124.951 68.6937C130.172 67.3603 132.28 61.138 133.166 55.8046C134.728 46.3665 132.306 37.6474 127.243 29.5034",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M110.203 26.0394C104.488 33.3598 97.3155 39.6345 89.2836 44.262C87.396 45.3601 85.0268 46.3797 83.1262 45.2947C81.9807 44.6411 81.3298 43.3731 80.9913 42.092C79.9629 38.1442 79.5985 28.1702 94.4255 12.5229C98.0314 8.71886 103.941 5.28089 109.214 4.61421C117.245 3.59458 120.995 7.01947 122.4 14.6928C118.547 15.8824 113.522 21.7779 110.19 26.0525L110.203 26.0394Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M95.402 11.3466C96.157 8.77136 96.4825 4.43142 95.376 2",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M57.7023 82.9949C57.7023 82.9949 10.1489 134.277 15.8506 153.075C15.8506 153.075 31.654 200.513 46.8586 213.847L74.5211 198.618C69.0146 187.82 61.7768 176.892 57.494 165.598C50.5816 147.362 62.4928 138.669 75.8619 128.251",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M229.927 250.122L230.213 223.416H155.453V298.241",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M179.092 223.416H222.519V179.807H179.092V223.416Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M182.815 173.728C178.324 166.473 182.919 157.231 186.941 149.715C190.274 143.493 193.268 136.97 194.427 130.002C196.509 117.466 192.461 104.747 187.605 93.0081C182.75 81.2693 176.957 69.6743 175.291 57.0728C174.744 52.9028 175.876 47.1902 180.068 46.9549C185.366 46.6673 187.163 51.6086 192.266 47.334C195.924 44.2621 196.561 41.0071 195.885 36.458C194.882 29.7128 190.586 24.7192 189.076 18.1832C187.801 12.6537 191.524 7.37251 197.199 6.88884C204.294 6.28752 208.668 14.1177 209.358 20.2485C210.048 26.314 207.965 32.5625 209.332 38.5103C210.881 45.2294 216.439 50.1184 220.267 55.844C225.161 63.1513 227.296 72.2757 226.164 81.0078C224.745 91.9623 218.522 101.623 214.981 112.067C211.441 122.525 211.363 135.793 219.902 142.761C221.672 144.199 223.768 145.336 225.005 147.257C226.932 150.251 226.177 154.251 224.784 157.532C219.915 168.931 193.086 190.369 182.815 173.768V173.728Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M167.286 232.252V287.94",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M80.0012 252.789L69.6001 317.535",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M104.789 76.6941C104.789 76.6941 111.024 84.0014 118.314 81.9099L107.913 179.036",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M122.907 20.2485C123.831 25.2421 124.026 31.7782 119.418 33.9089C117.179 34.9416 114.393 34.4841 112.336 35.8567C110.227 37.2554 109.616 40.0659 108.067 42.079C106.517 44.0921 102.521 44.5758 102.065 42.079",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M85.5469 51.0205C85.5469 51.0205 92.4983 54.014 98.4473 66.9685",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M354.604 158.291L391.221 158.742",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M336.582 174.433L410.815 175.347",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M273.175 190.003L395.394 191.509",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M290.821 178.782L290.5 204.855",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M313.016 183.792L312.75 205.369",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M335.365 176.131L335.163 192.48",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M357.857 156.844L357.357 197.39",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M380.124 154.776L379.599 197.425",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M402.226 167.23L401.988 186.552",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M336.511 190.721C340.055 194.317 387.097 216.073 412.798 173.23C401.719 168.773 394.816 158.232 382.282 154.5C374.162 152.082 351.187 149.72 330.789 182.487C317.932 184.709 290.351 184.848 284.044 171.82C280.354 176.839 270.139 188.693 272.516 195.261C273.851 198.918 281.364 201.568 284.606 202.994C293.301 206.804 323.325 212.15 336.524 190.733L336.511 190.721Z",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      },
      {
        "d": "M127.45 100.25C129.272 130.839 143.136 160.552 165.383 181.533C161.217 185.154 156.505 188.513 151.064 189.298C145.622 190.082 139.348 187.507 137.525 182.291C138.163 185.899 138.775 189.128 139.608 192.553C140.441 195.978 139.191 200.697 135.69 200.958C132.709 201.18 130.704 197.991 129.584 195.206C124.377 182.2 122.841 167.742 125.198 153.925",
        "stroke": "#294C72",
        "strokeWidth": 2.97132,
        "fill": "none",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
      }
    ]
  }
];

const SkjerBuktaSVG = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    const paths = svgRef.current.querySelectorAll('path');
    if (keyframes.length < 2 || paths.length === 0) {
      console.error("Insufficient keyframes or no paths found in SVG.");
      return;
    }

    const timeline = anime.timeline({
      easing: "easeInOutSine",
      duration: 2200,
      loop: true,
      direction: "alternate",
      autoplay: true
    });

    keyframes.forEach((frame, index) => {
      frame.paths.forEach((pathData, pathIndex) => {
        if (index < keyframes.length - 1) {
          timeline.add({
            targets: paths[pathIndex],
            d: { value: keyframes[index + 1].paths[pathIndex].d },
            duration: 2200
          }, index * 2200);
        }
      });
    });
  }, []);

  return (
    <svg ref={svgRef} xmlns="http://www.w3.org/2000/svg" viewBox={keyframes[0].viewBox}>
      {keyframes[0].paths.map((path, index) => (
        <path
          key={index}
          d={path.d}
          stroke={path.stroke}
          strokeWidth={2}
          fill={path.fill}
          strokeLinecap={path.strokeLinecap}
          strokeLinejoin={path.strokeLinejoin}
        />
      ))}
    </svg>
  );
};

export default SkjerBuktaSVG;
