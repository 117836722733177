const sanityClient = require("@sanity/client");

const sanityConfig = {
  projectId: "3z1cfywg",
  dataset: "production",
  useCdn: true,
  apiVersion: "2022-08-29",
};

const client = sanityClient(sanityConfig);

module.exports = { client };
