import React, { useEffect, useRef, useState } from 'react';
import * as styles from './Popup.module.scss';
import { Heading3 } from '@ui/typography';
import { Button } from '@ui/buttons';
import SkjerBuktaSVG from '../SkjerBuktaSvg';

interface PopupProps {
  // Removed onClose, count, and resetCount props since Popup manages its own state now
}

const Popup: React.FC<PopupProps> = () => {
  const modalRef = useRef<HTMLDivElement>(null);

  // State to manage popup visibility
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Show popup only once
      const hasVisited = localStorage.getItem('hasVisited');
      if (!hasVisited) {
        setShowPopup(true);
        localStorage.setItem('hasVisited', 'true');
      }
    }

    const modal = modalRef.current;

    if (modal) {
      // Handler to close modal on 'Escape' key
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          handleClosePopup();
        }
        // Trap focus inside the modal
        if (e.key === 'Tab') {
          trapFocus(e, modal);
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      // Cleanup
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, []);

  // Function to trap focus within the modal
  const trapFocus = (e: KeyboardEvent, modal: HTMLDivElement) => {
    const focusableElements = modal.querySelectorAll<HTMLElement>(
      'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
    );
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (e.shiftKey) {
      // Shift + Tab
      if (document.activeElement === firstElement) {
        e.preventDefault();
        lastElement.focus();
      }
    } else {
      // Tab
      if (document.activeElement === lastElement) {
        e.preventDefault();
        firstElement.focus();
      }
    }
  };

  // Close modal when clicking outside
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Show on mobile only
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  if (!showPopup) {
    return null;
  }

  return (
    <div
      className={styles.popupOverlay}
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-heading"
      onClick={handleOverlayClick}
      ref={modalRef}
      tabIndex={-1}
    >
      <div className={styles.popupContent}>
        <button
          className={styles.closeButton}
          onClick={handleClosePopup}
          aria-label="Close Popup"
        >
          <svg
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 1L25.5 25" stroke="#294C72" strokeWidth="2" />
            <path d="M25.5 1L1.5 25" stroke="#294C72" strokeWidth="2" />
          </svg>
        </button>
        <div className={styles.contentContainer}>
          <div className={styles.mainContent}>
            {isMobile && (
              <Heading3
                fontSize="xlarge"
                uppercase
                fontWeight="fontWeightLight"
                className={styles.title}
              >
                Få det beste ut <br /> av Oslobukta
              </Heading3>
            )}
            <div className={styles.imageContainer}>
              <SkjerBuktaSVG />
            </div>

            <div className={styles.textContent}>
              {!isMobile && (
                <Heading3
                  fontSize="xlarge"
                  uppercase
                  fontWeight="fontWeightLight"
                  className={styles.title}
                >
                  Få det beste ut <br /> av Oslobukta
                </Heading3>
              )}
              <p>
                Hold deg oppdatert på alt som skjer i Oslobukta. Last ned appen og bli medlem for å få nyheter og eksklusive fordeler!
              </p>
              <div className={styles.actionButtonContainer}>
                <Button
                  outlined
                  withArrow
                  newTab
                  size="large"
                  
                  href='https://oslobukta.member.placewise.com/'
                  onClick={handleClosePopup}
                  className={styles.actionButton}
                >
                  Bli medlem
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;