// extracted by mini-css-extract-plugin
export var actionButton = "Popup-module--actionButton--ca967";
export var actionButtonContainer = "Popup-module--actionButtonContainer--0ec9d";
export var closeButton = "Popup-module--closeButton--881d3";
export var contentContainer = "Popup-module--contentContainer--c4181";
export var fadeIn = "Popup-module--fadeIn--87865";
export var image = "Popup-module--image--6a165";
export var imageContainer = "Popup-module--imageContainer--b0598";
export var mainContent = "Popup-module--mainContent--e12e3";
export var popupContent = "Popup-module--popupContent--60553";
export var popupOverlay = "Popup-module--popupOverlay--d464f";
export var slideIn = "Popup-module--slideIn--ec883";
export var textContent = "Popup-module--textContent--742c1";